<template>
  <v-container>
    <v-layout justify-center>
      <v-flex xs12 sm12 md12 lg12 xl12>
        <template>
          <v-data-table
            dense
            :headers="headers"
            :items="compPerfModLista"
            sortBy="id"
            class="elevation-10"
            :footer-props="{
              'items-per-page-options': [20],
            }"
          >
            <template v-slot:top>
              <v-toolbar flat dense>
                <v-icon class="mr-2" color="primary"
                  >mdi-account-circle-outline</v-icon
                >
                <v-toolbar-title>Módulos do Perfil</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="mr-2"
                      color="blue darken-1"
                      v-bind="attrs"
                      v-on="on"
                      @click="metCarregaLista"
                    >
                      mdi-refresh-circle
                    </v-icon>
                  </template>
                  <span>Refresh</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="mr-2"
                      color="teal darken-1"
                      v-bind="attrs"
                      v-on="on"
                      @click="dialog1 = true"
                    >
                      mdi-plus-circle
                    </v-icon>
                  </template>
                  <span>Inserir Módulo</span>
                </v-tooltip>

                <!-- <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="mr-2"
                      color="indigo"
                      v-bind="attrs"
                      v-on="on"
                      @click="metVoltar()"
                    >
                      mdi-arrow-left-circle
                    </v-icon>
                  </template>
                  <span>Voltar</span>
                </v-tooltip> -->
              </v-toolbar>

              <v-container>
                <v-row dense> </v-row>
              </v-container>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dense
                    class="mr-2"
                    color="red darken-1"
                    v-bind="attrs"
                    v-on="on"
                    @click="metDeleteItem(item)"
                  >
                    mdi-trash-can
                  </v-icon>
                </template>
                <span>Deletar</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.idModulo`]="{ item }">
              <span>{{ metObterModulo(item.idModulo) }} </span>
            </template>
          </v-data-table>
          <v-dialog
            v-if="dail1"
            v-model="dialog1"
            min-width="300px"
            max-width="400px"
          >
            <v-card>
              <v-card-title>
                <span class="text-h5">Inserir Módulo no Perfil</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="auto" md="12" align-self="center">
                      <v-autocomplete
                        hide-details="auto"
                        v-model="perfModLocal.idModulo"
                        label="Módulo"
                        :items="compModuloSelect"
                        item-text="modulo"
                        item-value="id"
                        outlined
                        clearable
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  dense
                  class="mb-4 white--text"
                  color="green darken-2"
                  @click="metSalvar"
                >
                  Salvar
                </v-btn>
                <v-btn
                  dense
                  class="ml-2 mb-4 white--text"
                  color="blue darken-1"
                  @click="metFecharDialog1"
                >
                  Cancelar
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" min-width="400px" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Deletar módulo?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="ml-2 mb-4 white--text"
                  color="red darken-1"
                  @click="metDeleteConfirm"
                >
                  Deletar
                </v-btn>
                <v-btn
                  class="ml-2 mb-4 white--text"
                  color="blue darken-1"
                  @click="metFecharDelete"
                >
                  Cancelar
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import register from "./../_store/register";

const { mapState, mapActions } = createNamespacedHelpers("adminPerfis");

export default {
  // mixins: [],
  name: "AdminPerfisModulosLista",
  data: () => ({
    listaPerfModAtivos: undefined,
    headers: [
      { text: "Código", value: "id", sortable: false },
      {
        text: "Módulo",
        value: "idModulo",
        align: "left",
        sortable: false,
      },

      { text: "Ações", value: "actions", sortable: false },
    ],
    dail1: true,
    dialog1: false,
    dialogDelete: false,
    perfModLocal: { id: null },
    perfModLocalDefault: { id: null },
    perfModDel: { id: null },
  }),

  computed: {
    ...mapState([
      "erro",
      "staPerfisModulos",
      "staPerfilSelecionado",
      "staModulosSelect",
    ]),

    compPerfModLista() {
      return this.staPerfisModulos;
    },

    compModuloSelect() {
      return this.staModulosSelect;
    },
  },

  created() {
    register(this.$store);
    this.actResetarPerfisModulos();
    this.listaPerfModAtivos = 2;
    this.metCarregaLista();
    this.actSelectModulos();
  },

  watch: {
    erro() {
      if (this.erro) {
        this.$root.snackbar.show({
          type: "danger",
          message: this.erro,
        });
        setTimeout(() => {
          this.$router.push({ name: "Login" });
        }, 3500);
      }
    },
  },

  methods: {
    ...mapActions([
      "actListarPerfisModulos",
      "actAtivarPerfMod",
      "actResetarPerfisModulos",
      "actSelectModulos",
      "actCriarPerfisModulos",
      "actDeletarPerfisModulos",
    ]),

    metObterModulo(id) {
      if (id != null && this.staModulosSelect.length != 0) {
        const ret = this.staModulosSelect.filter((c) => c.id == id);
        return ret[0].modulo;
      } else {
        const ret = "";
        return ret;
      }
    },

    async metSalvar() {
      const procura = this.staPerfisModulos.find(
        (t) => t.idModulo == this.perfModLocal.idModulo
      );
      if (procura != undefined) {
        this.$root.snackbar.show({
          type: "warning",
          message: "Já existe no perfil!",
        });
        this.metFecharDialog1();
        return;
      }

      this.perfModLocal.idPerfil = this.staPerfilSelecionado.id;
      await this.actCriarPerfisModulos({
        perfMod: this.perfModLocal,
      });

      this.$root.snackbar.show({
        type: "success",
        message: "Módulo Inserido!",
      });
      this.metFecharDialog1();
    },

    metDeleteItem(item) {
      this.perfModDel = Object.assign({}, item);
      this.dialogDelete = true;
    },

    metFecharDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.perfModLocal = Object.assign({}, this.perfModLocalDefault);
      });
    },

    async metDeleteConfirm() {
      // console.log("$", this.perfModDel);
      if (this.perfModDel) {
        await this.actDeletarPerfisModulos({
          perfMod: JSON.parse(JSON.stringify(this.perfModDel)),
        });
        this.metFecharDelete();
      }
    },

    metFecharDialog1() {
      this.$nextTick(() => {
        this.perfModLocal = Object.assign({}, this.perfModLocalDefault);
      });
      this.dialog1 = false;
      this.dail1 = false;
      this.$nextTick(() => {
        this.dail1 = true;
      });
    },

    metVoltar() {
      this.$router.back();
    },

    login() {
      this.$router.push({ name: "Login" });
    },

    async metCarregaLista() {
      if (this.staPerfilSelecionado != undefined) {
        await this.actListarPerfisModulos({
          perfil: this.staPerfilSelecionado,
        });
      }
    },
  },
};
</script>
